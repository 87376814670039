import states from './image-path-modifier.state.js'


// Services
import { eventEmit, base64toFile } from '@/services/utils/utils.service'
import { fileManager } from '@/services/states/states.service'

// Components
import MdiImageMultiple from 'vue-material-design-icons/ImageMultiple.vue'
import MdiQrcode from 'vue-material-design-icons/Qrcode.vue'
import MdiUpload from 'vue-material-design-icons/Upload.vue'
import MdiPencil from 'vue-material-design-icons/Pencil.vue'
import MdiAccount from 'vue-material-design-icons/Account.vue'

// Library
import {
  cloneDeep as _cloneDeep
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Image-path-modifier'

// Components
const components = {
  MdiImageMultiple,
  MdiQrcode,
  MdiUpload,
  MdiPencil,
  MdiAccount
}

// Properties
const props = {
  mjml: Object,
  isDisplayed: Boolean,
  customKey: {
    type: String,
    default: function () {
      return 'src'
    }
  }
}

// Data
const data = function () {
  return {
    states: _cloneDeep(states),
    focused: false
  }
}

const computed = {
  // Func@fileManagerActive
  /**
   * File manager status
   * @return {Boolean}
   */
  fileManagerActive () {
    return fileManager().get()
  },
  // Func@qrcodeManagerActive

  /**
   * Has image loaded
   * @return {Boolean}
   */
  imageEditorActive () {
    return this.mjml.attributes[this.customKey]
  },

  isUrlValid () {
    if (this.states.href.length > 0) {
      let rgx = ''
      const isLink = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(this.states.href)
      if (this.states.href.includes('http') || isLink) {
        const link = /(http|https)?:\/\/(?:www\.|(?!www\.))([a-zA-Z0-9-[\]=#_/+%!]+)?([-]{0,1}[a-zA-Z0-9-[\]=#_/+%!]){0,}((?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:<%[^%<>]{1,}%>)|(?:#[^#\s]{1,})){0,}([a-zA-Z0-9-[\]=#_/+%!]+)?([-]{0,1}[a-zA-Z0-9-[\]=#_/+%!])?(?:\.(([a-zA-Z0-9-[\]=#_/+%!:,']){0,}((?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:<%[^%<>]{1,}%>)|(?:#[^#\s]{1,})){0,})){1,}(?:\?(([a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}((?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:<%[^%<>]{1,}%>)|(?:#[^#\s]{1,})){0,})([a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}){0,}(?:&(([a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}((?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:<%[^%<>]{1,}%>)|(?:#[^#\s]{1,})){0,}([a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}((?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:<%[^%<>]{1,}%>)|(?:#[^#\s]{1,})){0,})([a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}([-]{0,1}[a-zA-Z0-9=\-[\]#_+%!{}:,.()/?']){0,}){0,}/gm
        rgx = new RegExp(link)
        return this.states.href.match(rgx) && this.states.href.match(rgx)[0].length === this.states.href.length && this.states.href.startsWith('http')
      } else {
        const perso = /((?:^[<%][^%<>]{1,}%>)|(?:{{[^}{]{1,}}})|(?:%%[^%]{0,}%%)|(?:#[^#\s]{1,})){0,}[a-zA-Z0-9-[\]=#_\\/+%!?&. ]{0,}((?:<%[^%<>]{1,}%>)|(?:{{[^}{]{1,}}})|(?:%%[^%]{1,}%%)|(?:#[^#]{1,}#)){0,}/gm
        rgx = new RegExp(perso)
        return this.states.href.match(rgx) && this.states.href.match(rgx).join('').length === this.states.href.length
      }
    }
    return true
  },

  href: {
    // getter
    get () {
      return this.states.href.replace(/&quot;/g, '"')
    },
    // setter
    set (newValue) {
      let val = newValue.replace(/"/g, '&quot;') // Fix MJML issue with with `"` in html attr
      this.$set(this.states, 'href', val)
      if (this.isUrlValid) {
        this.$set(this.mjml.attributes, this.customKey, this.states.href)
        this.$emit('change')
      }
    }
  }
}

// Methods
const methods = {
  /**
   * Check file validity
   * @param {Object} file
   */
  isValidFile (file) {
    let errorData
    const fileSize = file.size / 1024 / 1024 // in MB

    switch (true) {
      // If file size too large
      case fileSize > 10:
        errorData = {
          title: this.$t('t_upload-image-error-title_'),
          message: this.$t('t_upload_image_error-message_')
        }
        eventEmit('error', errorData)
        return false

      default:
        return true
    }
  },

  /**
   * Function to handle upload
   * Sets the src attribute of the image with the new path.
   * @param  {file} object (file object)
   */
  uploadImage: function (file) {
    if (!this.isValidFile(file.file)) return
    if (!this.$route.params.id) {
      const _URL = window.URL || window.webkitURL
      const img = new Image()
      img.onload = () => {
        const url = `https://via.placeholder.com/${img.width}x${img.height}`
        this.mjml.attributes[this.customKey] = url
      }
      img.src = _URL.createObjectURL(file.file)
      return
    }

    const headers = { 'content-type': 'application/json' }
    const url = `/api/htmls/${this.$route.params.id}/images`
    const data = new FormData()

    data.append(file.filename, file.file)
    headers['Content-Type'] = 'multipart/form-data'

    this
      .axios
      .post(url, data, { headers })
      .then(response => {
        this.mjml.attributes[this.customKey] = response.data.url
        this.href = response.data.url
      })
  },

  /**
   * Function to set url to current componenent from event
   * Sets the src attribute of the image with the new path.
   * @param  {data} object   (object sent from event)
   */
  setFileUrlFromEvent: function (event) {
    if (typeof event.data !== 'object') { return }
    const call = event.data.call
    const value = _cloneDeep(event.data.value)
    if (call === 'file-manager:file') {
      if (value.url && value.fallback === 'settings') {
        this.mjml.attributes[this.customKey] = value.url
        this.href = value.url
      }
    }
    if (call == 'personalization-fields-manager:text') {
      if (!value.text || value.fallback !== 'settings')
        return

      var cursorPosition = document.querySelector("#image-path-input").selectionStart

      if (this.mjml.attributes[this.customKey].length === 0) {
        this.mjml.attributes[this.customKey] = value.text
        this.href = value.text
      } else if (cursorPosition > 0) {
        this.mjml.attributes[this.customKey] = (
          this.mjml.attributes[this.customKey].slice(0, cursorPosition)
          + value.text
          + this.mjml.attributes[this.customKey].slice(cursorPosition)
        )
        this.href= this.mjml.attributes[this.customKey]
      }
      else {
        this.mjml.attributes[this.customKey] += value.text
        this.href += value.txt
      }
    }
  },

  /**
   * Function to handle opening of file manager
   */
  openFileManager: function () {
    eventEmit('open-file-manager', { category: 'image', fallback: 'settings' })
  },

  /**
   * Function to handle opening of qrcode manager
   */
  openQrcodeManager: function () {
    eventEmit('open-qrcode-manager', { fallback: 'settings' })
  },

  /**
  * Function to handle opening of personalization fields manager
  */
  openPersonalizationFieldsManager: function () {
    eventEmit('open-personalization-fields-manager', { fallback: 'settings' })
  },

  /**
   * Function to handle opening of filerobot Image Editor
   */
  openImageEditor () {
    const translations = {}
    translations[this.$locale] = {
      'toolbar.download': this.$t('t_image-editor-download_')
    }
    const config = {
      tools: ['rotate', 'crop', 'resize', 'adjust', 'effects', 'filters'],
      language: this.$locale,
      colorScheme: 'light',
      translations
    }
    this.ImageEditor = new window.FilerobotImageEditor(config)
    this.ImageEditor.open(this.mjml.attributes[this.customKey])
    // Add custom editor button
    setTimeout(() => {
      const imageEditorElt = document.querySelectorAll('#filerobot-image-editor')[1]
      imageEditorElt.addEventListener('click', this.onImageEditorInteraction)
      // Add custom save button
      const btnSave = document.createElement('button')
      btnSave.innerHTML = this.$t('t_image-editor-confirm_')
      btnSave.classList.add('image-editor-btn-save')
      btnSave.style.cssText = 'position: absolute; z-index: 10000; top: 70px; right: 7px; color: white; background-color: #3DB28C; border: 0; padding: 7px 27px; border-radius: 4px; font-size: 13px;'
      btnSave.addEventListener('click', this.onCloseImageEditor)
      imageEditorElt.appendChild(btnSave)

      // Add custom Download button

      // Hide built-in download element
      const divHideDownload = document.createElement('div')
      divHideDownload.classList.add('hide-download-element')
      divHideDownload.style.cssText = 'position: absolute; z-index:9999; top: 38px; right: 2px; width: 110px; height: 94px; background-color: #FFF; display: block;'
      imageEditorElt.appendChild(divHideDownload)
    }, 50)
  },

  onImageEditorInteraction () {
    // Check editorImage state. If a parameter is being modified, we hide the original download button
    setTimeout(() => {
      const isModifyingImage = document.querySelector('div[name="rotate"]') == null
      if (document.querySelectorAll('#filerobot-image-editor')[1]) {
        document.querySelector('.hide-download-element').style.display = isModifyingImage ? 'none' : 'block'
        document.querySelector('.image-editor-btn-save').style.display = isModifyingImage ? 'none' : 'block'
      }
    }, 50)
  },

  onCloseImageEditor () {
    const canvas = document.getElementById('scaleflex-image-edit-box')
    if (!canvas) {
      console.warn('image editor: Image not found')
      return
    }

    const dataUrl = canvas.toDataURL()
    const id = Math.floor(Math.random() * Math.floor(999999)) // nosemgrep
    base64toFile(dataUrl, 'image-' + id + '.png')
      .then((file) => {
        this.uploadImage({ file, filename: 'file' })
        this.ImageEditor.close()
      })
  },
}

const watch = {
  /**
   * Set isDisplayed instead of on created / destroyed because we got event duplication
   * when a left panel with this component exist and when a deep edit panel is also opened
   */
  'isDisplayed': {
    immediate: true,
    handler (isTrue) {
      if (isTrue) { window.addEventListener('message', this.setFileUrlFromEvent) } 
      else { window.removeEventListener('message', this.setFileUrlFromEvent) }
    }
  }
}

// Func@removeWindowListener on destroy
/**
 * Remove event listener on destroy what ever the reason
 */
function destroyed () {
  window.removeEventListener('message', this.setFileUrlFromEvent)
}
// Func@removeWindowListener

function mounted() {
  this.states.href = this.mjml.attributes[this.customKey]
}

// Vue component syntax
export default {
  name,
  data,
  props,
  watch,
  methods,
  computed,
  components,
  mounted,
  destroyed
}
