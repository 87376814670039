import { landingPageMode, qrcodeManager } from '@/services/states/states.service'

export default {
  sections: {
    basics: 't_basics_',
    advanced: 't_advanced_',
    mobile: 't_mobile_'
  },
  activeItems: [],
  items: [
    // Object@mjTextContent
    {
      name: 't_text_',
      state: null,
      type: 'basics',
      icon: 'mdi-text',
      componentId: 'mj-text'
    },
    // Object@mjTextContent

    // Object@mjImageContent
    {
      name: 't_image_',
      state: null,
      type: 'basics',
      icon: 'mdi-image',
      componentId: 'mj-image'
    },
    // Object@mjImageContent

    // Object@mjDividerContent
    {
      name: 't_divider_',
      state: null,
      type: 'basics',
      icon: 'mdi-minus',
      componentId: 'mj-divider'
    },
    // Object@mjDividerContent

    // Object@mjButtonContent
    {
      name: 't_button_',
      state: null,
      type: 'basics',
      icon: 'mdi-cursor-pointer',
      componentId: 'mj-button-v2'
    },
    // Object@mjButtonContent

    // Object@mjSpacerContent
    {
      name: 't_spacer_',
      state: null,
      type: 'basics',
      icon: 'mdi-arrow-split-horizontal',
      componentId: 'mj-spacer'
    },
    // Object@mjSpacerContent

    // Object@mjHtmlContent
    {
      name: 't_html_',
      state: null,
      type: 'basics',
      icon: 'mdi-code-tags',
      componentId: 'mj-raw'
    },
    // Object@mjHtmlContent

    // Object@mjCarouselContent
    {
      name: 't_carousel_',
      state: null,
      type: 'basics',
      icon: 'mdi-image-multiple',
      componentId: 'mj-carousel-v2'
    },
    // Object@mjCarouselContent

    // Object@mjAccordionContent
    {
      name: 't_accordion_',
      state: null,
      type: 'basics',
      icon: 'mdi-menu-swap',
      componentId: 'mj-accordion'
    },
    // Object@mjAccordionContent

    // Object@mjNavbarContent
    {
      name: 't_navbar_',
      state: null,
      type: 'basics',
      icon: 'mdi-menu',
      componentId: 'mj-navbar',
      childrenNbr: 3
    },
    // Object@mjNavbarContent

    // Object@mjScratchContent
    {
      checkActive: landingPageMode,
      tooltip: 't_scratch-tooltip_',
      consumable: 1,
      name: 't_scratch_',
      type: 'basics',
      icon: 'mdi-eraser',
      componentId: 'mj-scratch'
    },
    // Object@mjScratchContent

    // Object@mjImage#QrcodeContent
    {
      checkActive: qrcodeManager,
      name: 't_qrcode_',
      state: 'new',
      type: 'basics',
      icon: 'mdi-qrcode',
      componentId: 'mj-image#qrcode'
    },
    // Object@mjImage#QrcodeContent

    // Object@mjVideo
    {
      name: 't_video_',
      state: 'new',
      type: 'basics',
      icon: 'mdi-play-box',
      componentId: 'mj-video'
    }
    // Object@mjVideo

  ]
}
