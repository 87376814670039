// Component states
import states from './edit-panel.state.js'

// Components
import MdiMjSpacer from 'vue-material-design-icons/ArrowSplitHorizontal.vue'
import MdiMjButtonV2 from 'vue-material-design-icons/CursorPointer.vue'
import MdiMjButton from 'vue-material-design-icons/CursorPointer.vue'
import MdiMjText from 'vue-material-design-icons/FormatText.vue'
import MdiMjDivider from 'vue-material-design-icons/Minus.vue'
import MdiMjImage from 'vue-material-design-icons/Image.vue'
import MdiDelete from 'vue-material-design-icons/Delete.vue'
import MdiMjCarouselV2 from 'vue-material-design-icons/ViewCarousel.vue'
import MdiMjNavbar from 'vue-material-design-icons/Menu.vue'
import MdiMjAccordion from 'vue-material-design-icons/MenuSwap.vue'
import MdiClose from 'vue-material-design-icons/Close.vue'
import MdiMjSectionV2 from 'vue-material-design-icons/TableRow.vue'
import MdiMjSection from 'vue-material-design-icons/TableRow.vue'
import MdiMjRaw from 'vue-material-design-icons/CodeTags.vue'
import MdiMjColumn from 'vue-material-design-icons/TableColumn.vue'
import MdiAlertCircle from 'vue-material-design-icons/AlertCircle.vue'
import MdiMjScratch from 'vue-material-design-icons/Eraser.vue'
import MdiMjImageQrcode from 'vue-material-design-icons/Qrcode.vue'
import MdiMjVideo from 'vue-material-design-icons/PlayBox.vue'
import ComponentLoader from '@/components/standalone/component-loader/Component-loader.vue'

// Services
import {
  globalstates,
  closeEditPanel,
  lockEditPanel
} from '@/services/states/states.service'

// Config file
import shortcuts from '@/assets/config/shortcuts/shortcuts.conf'

// Library
import {
  map as _map,
  get as _get,
  drop as _drop,
  uniqueId as _uniqueId,
  cloneDeep as _cloneDeep
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Edit-panel'

// Vue@data
const data = function () {
  return {
    states,
    shortcuts,
    edit: _get(globalstates, 'leftPanel.editPanel', {})
  }
}
// Vue@data

// Vue@subComponents
const components = {
  MdiMjImageQrcode,
  ComponentLoader,
  MdiMjCarouselV2,
  MdiAlertCircle,
  MdiMjDivider,
  MdiMjSection,
  MdiMjSectionV2,
  MdiMjScratch,
  MdiMjColumn,
  MdiMjButton,
  MdiMjButtonV2,
  MdiMjImage,
  MdiDelete,
  MdiMjText,
  MdiClose,
  MdiMjRaw,
  MdiMjSpacer,
  MdiMjNavbar,
  MdiMjAccordion,
  MdiMjVideo
}
// Vue@subComponents

// Methods
const methods = {

  // Func@closePanel
  /**
   * closePanel
   * Set panel inactive and clean content
   */
  closePanel () {
    closeEditPanel()
  },
  // Func@closePanel

  // Func@switchModifier
  /**
   * Switch pane mode (General/Structure/Contents)
   */
  switchModifier () {
    const modifiers = this.states.conf.modifiers
    const index = parseInt(this.states.components.active.slice(-1))
    const nextModifier = _get(modifiers, `${index + 1}.title`, modifiers[0].title)
    const i = index >= modifiers.length - 1 ? 0 : index + 1

    this.$set(states.components, 'active', `${nextModifier}_${i}`)
  },
  // Func@switchModifier

  getComponentName (content) {
    let dndComponentId
    const contentClass = _get(content, 'attributes.css-class', '')
    const dndComponentIdClass = contentClass.split(' ').find(c => c.includes('dcid_'))
    if (dndComponentIdClass) dndComponentId = dndComponentIdClass.split('dcid_').pop()

    return dndComponentId ? `${content.tagName}-${dndComponentId}` : content.tagName
  },

  // Func@stateListUpdate
  /**
   * Add or remove perso state
   * @param  {String} targetName (name of the state to remove)
   * @param  {String} action     (name of the requested action)
   */
  stateListUpdate (targetName, action) {
    const states = this.content.children
    const confType = this.states.conf.type
    const modifierIndex = this.states.modifiers.defaultIndex[confType]

    switch (action) {
      case 'add': {
        const newContents = _cloneDeep(this.content.children[this.stateIndex])
        newContents.map(content => {
          content.id = _uniqueId(`${content.tagName}_`)
          return content
        })
        states.push(newContents)
        this.$set(this.states.components, 'stateActive', `state-${states.length - 1}`)
        break
      }

      case 'remove': {
        const activeStateIsAfter = this.stateIndex >= targetName.split('-')[1]
        if (activeStateIsAfter) {
          this.$set(this.states.components, 'stateActive', `state-${states.length - 2}`)
          this.content.attributes.activeStateIndex = states.length - 2
        }
        this.content.attributes.states.splice(targetName.split('-')[1], 1)
        states.splice(targetName.split('-')[1], 1)
        break
      }
    }

    this.states.components.active = `${this.states.conf.modifiers[modifierIndex].title}_${modifierIndex}`
  },
  // Func@stateListUpdate

  // Func@displayAlertIcon
  /**
   * Display an alert icon close to the modifier title in
   * edit panel accordion menu depending alert type
   * @param  {Object}  modifier    (modifier config)
   * @param  {Integer} stateIndex  (state index)
   *
   * @return {Boolean}
   */
  displayAlertIcon (modifier) {
    let currentStateWithIssue
    const hasAlert = this.edit.errorType

    switch (_get(modifier, 'conf.alertType')) {
      case 'state': {
        const operationList = _get(this.content, `attributes.states[${this.stateIndex}].operations`, [])
        currentStateWithIssue = operationList.length === 0 && this.stateIndex > 0
        break
      }
      // When described alerts types are detected
      case 'wrong_url':
      case 'structure':
      case 'incomplete': {
        currentStateWithIssue = true
        break
      }
      default: {
        currentStateWithIssue = false
      }
    }

    return hasAlert && currentStateWithIssue
  },
  // Func@displayAlertIcon

  /**
   * [enrichedModifierConf description]
   * @param  {Object} modifier [description]
   *
   * @return {Object}          [description]
   */
  enrichedModifierConf (modifier) {
    const modifierClone = _cloneDeep(modifier)
    const parentDataShared = _get(this.edit, 'parent')
    const nestedConf = _get(modifierClone, 'conf.nested')
    const isNested = _get(parentDataShared, 'mjml.tagName') === 'mj-column'
    const parentAttributesRequested = _get(modifier, 'conf.parentAttr') || _get(nestedConf, 'parentAttr')
    let conf = { stateIndex: this.stateIndex }
    
    // Detect if it's nested component then update conf
    if (isNested && nestedConf) conf = { ...conf, ...nestedConf, isNested: true }
    // Detect if parent attr is requested
    
    if (parentAttributesRequested && parentDataShared) {
      conf.parent = {}
      let key
      parentAttributesRequested.forEach(attrKey => {
        switch (attrKey) {
          case 'width':
            key = parentDataShared.mjml.attributes[attrKey] === ''
              ? `${100 / parentDataShared.columns}%`
              : parentDataShared.mjml.attributes[attrKey]
            break
          case 'background-url':
            key = parentDataShared.mjml.attributes[attrKey] === ''
              ? 'empty'
              : parentDataShared.mjml.attributes[attrKey]
            break
          default:
            key = parentDataShared.mjml.attributes[attrKey]
        }
        conf.parent[attrKey] = key
      })
    }

    const updatedconf = Object.assign(conf, modifierClone.conf)

    // clean components conf
    if (updatedconf.nested) delete updatedconf.nested
    if (updatedconf.parentAttr) delete updatedconf.parentAttr

    return updatedconf
  },

  // Func@loadConfiguration
  /**
   * Load MJML element component configuration on demand
   */
  async loadConfiguration () {
    if (!this.content) return
    this.states.conf = {}
    const resource = `${_get(this.content, 'tagName', 'unknow')}.conf`

    try {
      if (this.isDynamic) this.states.components.stateActive = `state-${this.content.attributes.activeStateIndex}`
      const module = await import(/* webpackMode: "eager" */ '@/assets/config/mjml-components/' + resource + '.json')
      const conf = { ...module } // module to obj
      const index = this.states.modifiers.defaultIndex[conf.type]

      // Check if the component have dnd componentId and commute modifiers when needed.
      // A single MJML component can be used for multiple dnd component
      // exemple with mj-image which is used for image or qrcode dnd component but
      // with different configuration
      let dndComponentId
      const contentClass = _get(this.content, 'attributes.css-class', '')
      const dndComponentIdClass = contentClass.split(' ').find(c => c.includes('dcid_'))
      if (dndComponentIdClass) dndComponentId = dndComponentIdClass.split('dcid_').pop()
      const modifiers = conf.modifiers[dndComponentId] || conf.modifiers.default
      conf.modifiers = modifiers

      this.states.components.active = `${modifiers[index].title}_${index}`
      this.states.conf = conf
    } catch (err) {
      console.error(err)
    }
  },
  // Func@loadConfiguration


 
  getContentParentSections(list) {
    //return => parcourir list tous les children(section).children(column).children => si children.id === this.edit.id (parent identifié) => retourner tous les elements de type mj-section
    // si child c'est mj-section getContentParentSections(child.children) === structure imbriquée
  
    return list
  }
}

// Computed Methods
const computed = {

  // Func@isDeletable
  /**
   * Content is can be deleted
   * @return {Boolean}
   */
  isDeletable () {
    return !(this.edit.contents.length === 1 && this.content.tagName === 'mj-column')
  },
  // Func@isDeletable

  /**
   * Binary to identify nested row
   * @returns {Boolean}
   */
  isNestedRow () {
    return this.edit.tagName === 'mj-section' && _get(this.edit.parent, 'mjml.tagName') === 'mj-column'
  },

  // Func@content
  /**
   * Current content to edit (can be structure or contents app)
   * @return {Object} (mjml object)
   */
  content () {
    return _get(this.edit, `contents[${this.edit.index}]`)
  },
  // Func@content

  // Func@stateIndex
  /**
   * Current state index (dynamic contents)
   * @return {Integer}   (state)
   */
  stateIndex () {
    const state = _get(this.states.components, 'stateActive')
    return state ? state.split('-')[1] : 0
  },
  // Func@stateIndex

  // Func@isDynamic
  /**
   * Check if it's dynamic content
   * @return {Boolean}
   */
  isDynamic () {
    return Boolean(this.content.attributes.states)
  },
  // Func@isDynamic

  // Func@getError
  /**
   * Get error message depending error type
   * @return {Object} (Error message, Error type)
   */
  getError () {
    const errorType = this.edit.errorType
    const errorMessage = this.states.errorMessages[errorType]

    switch (errorType) {
      case 'state':
        return {
          message: this.$t(errorMessage, {
            index: this.states.components.stateIndexError
          }),
          type: errorType
        }
      default:
        return {
          message: this.$t(errorMessage),
          type: errorType
        }
    }
  },
 
  // Func@getError
}

// Func@mounted
function mounted () {
  this.$nextTick(function () {
    setTimeout(() => {
      for (const section of this.$refs.sections) {
        section.$el
          .querySelector('.el-collapse-item__header')
          .setAttribute('aria-label', section.$el.getAttribute('aria-label'))
      }
    })
  })
}

// Whatched properties
const watch = {

  // Vue@watchContent
  /**
   * Load configuration on content update
   * @param  {Object} newVal (new content)
   * @param  {Object} oldVal (previous content)
   */
  content: function (newVal, oldVal) {
    if (_get(newVal, 'id') === _get(oldVal, 'id')) return
    this.loadConfiguration()
  },
  // Vue@watchContent

  // Vue@watchStateActive
  /**
   * Switch data corresponding to the selected state
   * @param  {Object} newVal (new state name)
   * @param  {Object} oldVal (old state name)
   */
  'states.components.stateActive': function (newVal, oldVal) {
    if (newVal === oldVal) return
    this.content.attributes.activeStateIndex = this.stateIndex
  },
  // Vue@watchStateActive

  // Vue@watchAttributesStates
  /**
   * Switch data corresponding to the selected state
   * @param  {Object} newVal (new state name)
   */
  'content.attributes.states': {
    handler (newVal) {
      const stateIndexError = _drop(_map(newVal, 'operations.length')).indexOf(0)
      const hasEmptyState = stateIndexError !== -1

      if (hasEmptyState) this.states.components.stateIndexError = stateIndexError + 1
      lockEditPanel(hasEmptyState, 'state')
    },
    deep: true
  }
  // Vue@watchAttributesStates
}

// Func@loadConfiguration
/**
 * Load edit panel configuration depending MJML tag and
 * set first component as open by default
 */
function loadConfiguration () {
  this.loadConfiguration()
}
// Func@loadConfiguration

// Vue component syntax
export default {
  name,
  data,
  watch,
  methods,
  mounted,
  computed,
  components,
  beforeMount: loadConfiguration
}
